<template>
    <div class="notice">
        <Header :title="title"></Header>
        <!-- <vue-element-loading :active="isActive" spinner="bar-fade-scale" color="#FF6700" is-full-screen/> -->
        <div class="container">
            <ul class="board_list last-border-0">
                <li v-for="(item,index) in List" :key="index" class="border-bottom fadeInUp">
                    <router-link :to="`/${cate}/notice/${item.no}`">
                        <h6 class="fw-bold">{{item.title}}</h6>
                        <div class="info mt-1 md-mt-3">
                            <span class="text-grey right_line">{{item.date}}</span>
                            <span class="text-grey right_line">{{item.type}}</span>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>

        <div v-if="cnt != 0">
            <h5 class="py-3 border-bottom cursor-pointer text-center small" @click="MoreCursor()">더보기 <i class="fal fa-chevron-down"></i> </h5>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title: "공지사항",
            cate: "cs",

            isActive: true,
            api : this.$store.state.api,

            List: [
                {
                    no:0,
                    title:'서비스 이용 안내',
                    date:'2023-08-16T21:00:12',
                    type:'공지'
                },
            ],
            allList:[
                {
                    no:0,
                    title:'서비스 이용 안내',
                    date:'2023-08-16T21:00:12',
                    type:'공지'
                },
                {
                    no:1,
                    title:'출시 기념 가입 이벤트',
                    date:'2023-08-16T21:00:12',
                    type:'이벤트'
                },
                {
                    no:2,
                    title:'출시 기념 가입 이벤트',
                    date:'2023-08-16T21:00:12',
                    type:'이벤트'
                },
                {
                    no:3,
                    title:'출시 기념 가입 이벤트',
                    date:'2023-08-16T21:00:12',
                    type:'이벤트'
                },
            ],
            cnt: 0,
            cursor: 0,
        }
    },
    methods:{
        MoreCursor() {
            this.isActive = true;
            const cursor = this.cursor;
            const cnt = this.cnt;

            allList.forEach(el => {
                this.List.push(el);
            });
            this.isActive = false;

        },
    }
}
</script>
<style lang="scss" scoped>
    .board_list{
        li{
        text-align: left;

            a{
            display: block;
            font-size: 80%;
            padding: 14px 0;

            h4{
                font-size: 18px;
            }
            }
        }
    }
    .right_line:last-of-type::after{
        display:none;
    }
</style>