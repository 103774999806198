<template>
    <div class="notice">
        <Header :title="title"></Header>
        <div class="container">
            <!-- 서식 1 -->
            <p class="fw-bold">{{list.title}}</p>
            <small class="text-grey">{{list.date}} <span v-if="list.type">|</span> {{list.type}}</small>
            <hr>
            <!-- <p class="fs-sm" v-html="list.content"></p> -->
            <pre class="fs-sm" v-html="list.content"></pre>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
export default {
    components:{Header},
    data(){
        return{
            title:'공지사항',
            idx: this.$route.params.idx,

            list:{
                title:'서비스 이용 안내',
                date:'5월 27일 08:30',
                type:'공지',
                content:'안녕하세요.<br>마이파킹 서비스 이용과 관련하여 첨부 문서와 같이 안내합니다.'
            },
        }
    },
    created(){
    },
    mounted() {
    },
    methods: {
    },
}
</script>

<style>

</style>